*, *:before, *:after {
  box-sizing: border-box;
}

/* Home */
.container {
  height: 100vh;
  width: 100vw;
  border: 30px solid #f5c242;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo_home {
  width: 100%;
  max-width: 800px;
}

.button_home {
  margin: 20px;
  width: 140px;
  height: 40px;
  border-radius: 10px;
  border : 0;
}

.buttonText_home{
  color: white;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
}

/* Category Selector */
.categorySelector_container {
  border: 30px solid #f5c242;
  max-height: 100vh;
  min-height: 100vh;
  overflow: scroll;
}

.row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.logo_category {
  max-width: 300px;
}

.image_category {
  width: 200px;
  height: 200px;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.image_category:hover {
  filter: grayscale(0);
  -webkit-filter: none;
}
.title_category{
  position: absolute;
  bottom: 10px;
  right:10px;
  text-decoration: none;
  color: white;
  font-weight: 800;
  text-align: right;
  max-width: 140px;
}
.category {
  margin: 20px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  border: 5px solid #f5c242;
  position: relative;
  border-radius: 5px;
}

.category:hover {
  cursor: pointer;
}

.content_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.title_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 200px;
}

.choose_title {
  margin-left: 12px;
  font-size: 40px;
  font-weight: 800;
}

/* Category view */

.nav_categories{
  width: 55vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.category_item{
    margin: 20px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    color: black;
}

.collections_container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 60px;
  overflow: scroll;
  height: 100vh;
  max-height: calc(100vh - 280px);
}

.collection_row{
  display: flex;
}

.collection_title{
  text-decoration: underline;
  margin-left: 60px;
}

.image_theme{
  margin: 10px;
}

.image_container {
  display:flex;
  justify-content:center; 
  align-items:center;
  flex-direction:column;
  cursor: pointer;
}
.modal{
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.MuiSvgIcon-root{
  color: white;
  margin-left: 10px;
}

.button_download {
  margin: 20px;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border : 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.image_modal {
  max-width: 600px;
  max-height: 50vh;
}

.dont_display{
  display: none;
}

.carousel_button {
  position: absolute;
  top: 0;
  width: 50%;
  height: 50px;
  border: none;
  font-size: 18px;
  font-weight: 700;
}

/* Error */

.error_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Multiple style */
a{
  text-decoration: none;
}
p{ 
  margin: 0;
}
.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 150px;
  margin-top: 10px;
}
body {
  max-height: 100vh;
}

.loading {
  border: 30px solid #f5c242;
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading_category{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
}

.loading_text{
  text-align: center;
}

.collection_name {
  margin-left: 60px;
  height: 38px;
}

@media (max-width: 768px) {
  /* Category Selector */

  .logo_category {
    width: 100%;
  }

  .choose_title {
    text-align: center;
    font-size: 20px;
    font-weight: 800;
  }

  /**/
  .collections_container {
    align-items: center;
    width: 100%;
    padding: 0px;
  }

  /* Multiple style */

  .header{
    flex-direction: column;
  }
}
